<template>
  <base-form
    model="studentYear"
    @save="student_year_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.studentYear.loading"
    :currentValue="studentYear"
    :defaultValue="{ ...baseItem, residence_abroad_time: 0 }"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { required } from "vuelidate/lib/validators";
import _range from "lodash/range";
import { addToCacheFragmentArray } from "../../apollo/helpers";
import { STUDENT_STUDENT_YEARS_FRAGMENT } from "../../graphql/fragments";

const STUDENT_YEAR_RESIDENCE_FRAGMENT = gql`
  fragment studentYearResidence on StudentYear {
    id
    type
    residence_start
    residence_end
    residence_time

    residence_break_start
    residence_break_end
    residence_break_reason
    residence_break_time

    college_year

    chamber {
      id
      name
    }

    residence {
      id
      name
    }

    year {
      id
      name
    }

    residence_info_verified_at
    residenceInfoVerifiedBy {
      id
      complete_name
    }

    studentYearTag {
      id
      name
    }
  }
`;

export default {
  components: { BaseForm },
  name: "StudentYearResidenceForm",
  props: {
    student_year_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    student_year_id(val) {
      if (val == null) this.studentYear = {};
    },
  },

  apollo: {
    studentYear: {
      query: gql`
        query StudentYear($id: ID!) {
          studentYear(id: $id) {
            ...studentYearResidence
          }
        }
        ${STUDENT_YEAR_RESIDENCE_FRAGMENT}
      `,
      variables() {
        return {
          id: this.student_year_id,
        };
      },
      skip() {
        return this.student_year_id == null;
      },
      update: (data) => {
        const residence_break =
          data.studentYear.residence_break_start != null || data.studentYear.residence_break_end != null;

        return { ...data.studentYear, residence_break };
      },
    },
  },

  data() {
    return {
      newValue: {},
      studentYear: {},
      saving: false,
    };
  },

  computed: {
    fields() {
      return [
        {
          name: "year",
          type: "query",
          path: "client.years",
          query: gql`
            query Years($client_id: ID!, $ids: [ID!]!) {
              client(id: $client_id) {
                id
                years(excludeIds: $ids) {
                  id
                  name
                }
              }
            }
          `,
          variables: () => {
            return {
              client_id: this.baseItem.student.user.client.id,
              ids: this.baseItem.student.studentYears.map((studentYear) => studentYear.year.id),
            };
          },
          label: this.$t("year.name"),
          validation: {
            required,
          },
          isVisible: () => !this.student_year_id,
          cols: 12,
        },
        {
          name: "residence",
          type: "query",
          path: "me.managedResidences",
          query: gql`
            query ManagedResidences {
              me {
                id
                managedResidences {
                  id
                  name
                }
              }
            }
          `,
          label: this.$t("residence.name"),
          validation: {
            required,
          },
        },
        {
          name: "type",
          type: "autocomplete",
          items: ["S", "E", "R", "C", "T", "D", "M"].map((value) => ({
            text: this.$t(`options.studentYear.type.${value}`),
            value,
          })),
          validation: {
            required,
          },
        },
        {
          name: "residence_start",
          type: "date",
          validation: {
            required,
          },
        },
        {
          name: "residence_end",
          type: "date",
          validation: {
            required,
          },
        },
        {
          name: "residence_break",
          type: "checkbox",
        },
        {
          name: "residence_break_reason",
          type: "autocomplete",
          items: ["residence_abroad", "apprenticeship", "research"].map((value) => ({
            text: this.$t(`options.studentYear.residence_break_reason.${value}`),
            value,
          })),
          isVisible: ({ residence_break }) => residence_break,
          validation: {
            required,
          },
        },
        {
          name: "residence_break_start",
          type: "date",
          isVisible: ({ residence_break }) => residence_break,
          validation: {
            required,
          },
        },
        {
          name: "residence_break_end",
          type: "date",
          isVisible: ({ residence_break }) => residence_break,
          validation: {
            required,
          },
        },
        {
          name: "chamber",
          type: "query",
          path: "residence.chambers",
          query: gql`
            query ResidenceChambers($id: ID!) {
              residence(id: $id) {
                id
                chambers {
                  id
                  name
                }
              }
            }
          `,
          variables: ({ residence }) => ({ id: residence?.id }),
          skip: ({ residence }) => residence == null,
          label: this.$t("chamber.name"),
          bind: {
            clearable: true,
          },
        },
        {
          name: "college_year",
          type: "autocomplete",
          items: _range(1, 8).map((value) => ({
            text: value,
            value,
          })),
          no_translation: true,
        },
        {
          name: "studentYearTag",
          type: "query",
          path: "year.studentYearTags",
          query: gql`
            query StudentYearAvailableTags($id: ID!) {
              year(id: $id) {
                id
                studentYearTags {
                  id
                  name
                }
              }
            }
          `,
          variables: ({ year }) => ({ id: year?.id }),
          skip: ({ year }) => year == null,
          label: this.$t("studentYearTag.name"),
          validation: {
            required,
          },
        },
      ];
    },
  },

  methods: {
    update() {
      this.saving = true;

      const { residence_break, ...input } = this.newValue;

      if (!residence_break) {
        input.residence_break_start = null;
        input.residence_break_end = null;
        input.residence_break_reason = null;
      }

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "StudentYear",
            fragment: STUDENT_YEAR_RESIDENCE_FRAGMENT,
            fragment_name: "studentYearResidence",
          }),
          variables: {
            id: this.student_year_id,
            input,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      const { residence_break, ...input } = this.newValue;

      if (!residence_break) {
        input.residence_break_start = null;
        input.residence_break_end = null;
        input.residence_break_reason = null;
      }

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "StudentYear",
            fragment: STUDENT_YEAR_RESIDENCE_FRAGMENT,
            fragment_name: "studentYearResidence",
          }),
          variables: {
            input: this.newValue,
          },
          update: (store, { data: { createStudentYear } }) =>
            addToCacheFragmentArray(store, {
              fragment_info: {
                id: `Student:${this.baseItem.student.id}`,
                fragment: STUDENT_STUDENT_YEARS_FRAGMENT,
                fragmentName: "studentStudentYears",
              },
              array_path: "studentYears",
              newElement: createStudentYear,
            }),
        })
        .then(({ data: { createStudentYear } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createStudentYear);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
