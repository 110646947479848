<template>
  <base-events-chart :series="series" :labels="labels" :colors="colors"></base-events-chart>
</template>

<script>
import gql from "graphql-tag";
import BaseEventsChart from "./BaseEventsChart.vue";
import { ucFirst } from "../../../apollo/helpers";

export const PRESENCES_GROUPED_BY_MODE_FRAGMENT = gql`
  fragment presencesGroupedByMode on StudentYear {
    presencesGroupedByMode {
      mode
      total
      presences_count
    }
  }
`;

export default {
  props: {
    presencesGroupedByMode: { Array, required: true },
  },

  components: { BaseEventsChart },

  data() {
    return {
      colorMap: {
        live: this.$vuetify.theme.themes.light.primary,
        dual: this.$vuetify.theme.themes.light.accent,
        remote: this.$vuetify.theme.themes.light.warning,
        empty: this.$vuetify.theme.themes.light.secondary,
      },
    };
  },

  computed: {
    series() {
      return this.presencesGroupedByMode.map((row) => row.total / 3600.0);
    },

    labels() {
      return this.presencesGroupedByMode.map((row) => ucFirst(this.$t(row.mode ? `options.event.mode.${row.mode}` : "empty")));
    },

    colors() {
      return this.presencesGroupedByMode.map((row) => (row.mode ? this.colorMap[row.mode] : this.colorMap["empty"]));
    },
  },
};
</script>
