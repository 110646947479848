<template>
  <div>
    <v-row v-if="!$apollo.queries.studentYear.loading">
      <v-col md="4" cols="12">
        <v-card class="overflow-hidden mb-4" rounded="lg">
          <v-card-title>{{ $t("studentYear.education_credits") }}</v-card-title>
          <v-card-text class="text-center">
            <events-tag-chart :presences-grouped-by-tag="studentYear.presencesGroupedByTag" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" cols="12">
        <v-card class="overflow-hidden" rounded="lg">
          <v-card-title>{{ $t("event.mode") }}</v-card-title>
          <v-card-text class="text-center">
            <events-mode-chart :presencesGroupedByMode="studentYear.presencesGroupedByMode" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col md="4" cols="12">
        <v-card class="overflow-hidden" rounded="lg">
          <v-card-title>{{ $t("event.acknowledged") }}</v-card-title>
          <v-card-text class="text-center">
            <events-acknowledged-chart
              :presencesGroupedByAcknowledged="studentYear.presencesGroupedByAcknowledged"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="overflow-hidden" rounded="lg">
          <presences-table :studentYear="studentYear"></presences-table>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="overflow-hidden" rounded="lg">
          <base-table
            view-only
            :title="$t('studentYear.courseEnrollments')"
            :items="studentYear.courseEnrollments"
            :headers="headers"
          >
            <template v-slot:[`name`]="{ item }">
              <router-link :to="{ name: 'Course', params: { id: item.id } }">{{ item.name }} </router-link>
            </template>
            <template v-slot:[`tag.name`]="{ item }">
              <v-chip :color="item.tag?.color ?? ''" link label dark>
                {{ item.tag?.name ?? "Nessuno" }}
              </v-chip>
            </template>
          </base-table>
        </v-card>
      </v-col>
    </v-row>
    <v-skeleton-loader type="card" v-else />
  </div>
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";
import EventsModeChart, { PRESENCES_GROUPED_BY_MODE_FRAGMENT } from "./statistics/EventsModeChart.vue";
import EventsTagChart, { PRESENCES_GROUPED_BY_TAG_FRAGMENT } from "./statistics/EventsTagChart.vue";
import EventsAcknowledgedChart, {
  PRESENCES_GROUPED_BY_ACKNOWLEDGED_FRAGMENT,
} from "./statistics/EventsAcknowledgedChart.vue";
import BaseTable from "../tables/BaseTable.vue";
import PresencesTable, { PRESENCES_TABLE_FRAGMENT } from "./tables/PresencesTable.vue";

export default {
  name: "StudentYearEducation",
  components: {
    EventsModeChart,
    EventsTagChart,
    EventsAcknowledgedChart,
    BaseTable,
    PresencesTable,
  },
  props: {
    student_year_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      studentYear: {},
      moment,
      headers: [
        {
          text: this.$t("course.name"),
          value: "name",
          type: "template",
        },
        {
          text: this.$t("tag.name"),
          value: "tag.name",
          type: "template",
        },
      ],
    };
  },
  apollo: {
    studentYear: {
      query: gql`
        query StudentYear($id: ID!) {
          studentYear(id: $id) {
            id
            ...presencesGroupedByMode
            ...presencesGroupedByTag
            ...presencesGroupedByAcknowledged
            ...presencesTable
            courseEnrollments {
              id
              name
              tag {
                id
                name
                color
              }
            }
          }
        }
        ${PRESENCES_GROUPED_BY_MODE_FRAGMENT}
        ${PRESENCES_GROUPED_BY_TAG_FRAGMENT}
        ${PRESENCES_GROUPED_BY_ACKNOWLEDGED_FRAGMENT}
        ${PRESENCES_TABLE_FRAGMENT}
      `,
      variables() {
        return { id: this.student_year_id };
      },
    },
  },
};
</script>

<style></style>
