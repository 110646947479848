<template>
  <base-form
    model="degree"
    @save="degree_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.degree.loading"
    :currentValue="degree"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import _range from "lodash/range";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

const DEGREE_FRAGMENT = gql`
  fragment degreeForm on Degree {
    id
    type
    name
    date
    place
    grade

    attachment: file(name: "attachment") {
      id
      name
      link
    }

    student {
      id
    }
  }
`;

export default {
  components: { BaseForm },
  name: "DegreeForm",
  props: {
    degree_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    degree_id(val) {
      if (val == null) this.degree = {};
    },
  },

  apollo: {
    degree: {
      query: gql`
        query Degree($id: ID!) {
          degree(id: $id) {
            ...degreeForm
          }
        }
        ${DEGREE_FRAGMENT}
      `,
      variables() {
        return { id: this.degree_id };
      },
      skip() {
        return this.degree_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      degree: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "place",
          validation: {
            required,
          },
        },
        {
          name: "type",
          type: "autocomplete",
          items: ["degree", "certification", "open_badge", "attendance_certificate", "other"].map((value) => ({
            text: this.$t(`options.degree.type.${value}`),
            value,
          })),
          validation: {
            required,
          },
        },
        {
          name: "date",
          type: "date",
          default: moment().format("YYYY-MM-DD"),
          validation: {
            required,
          },
        },
        {
          name: "grade",
          type: "autocomplete",
          items: [..._range(66, 111).map((num) => num.toString()), "110L"].reverse(),
          no_translation: true,
        },
        { name: "attachment", type: "file" },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({ model: "Degree", fragment: DEGREE_FRAGMENT, fragment_name: "degreeForm" }),
          variables: {
            id: this.degree_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({ model: "Degree", fragment: DEGREE_FRAGMENT, fragment_name: "degreeForm" }),
          variables: {
            input: this.newValue,
          },
        })
        .then(({ data: { createDegree } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createDegree);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
