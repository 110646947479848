<template>
  <div>
    <v-row>
      <v-col cols="12">
        <edit-card
          :info="studentAnagraphicInfo"
          v-if="!loading"
          :item="student"
          model="student"
          :title="$tc('models.studentanagraphic', 1)"
          rounded="lg"
          @save="save"
          grow
          hideEmptyFields
        >
          <template v-slot:form="{ close }">
            <student-anagraphic-form :student_id="student.id" @cancel="close" @save="close" />
          </template>

          <template v-slot:action>
            <VerifyButton
              @verify="verifyAnagraphicProfile"
              :verifing="verifing"
              :verified-at="student.anagraphic_profile_verified_at"
              :verified-by="student.anagraphicProfileVerifiedBy"
              :can-verify="student.canVerifyAnagraphicProfile"
            ></VerifyButton>
          </template>
        </edit-card>
        <v-skeleton-loader type="card" v-else rounded="lg" />
      </v-col>

      <v-col cols="12">
        <v-card v-if="!loading" class="overflow-hidden" rounded="lg">
          <crud-table
            model="career"
            :items="loading ? [] : student.careers"
            :loading="loading"
            :title="$tc('models.career', 2)"
            :baseItem="{ student_id: student.id }"
            :viewOnly="!student.canCreateCareers"
            @deleteItem="deleteCareer"
          >
            <template v-slot:[`studyClass.name`]="{ item }">
              <router-link
                v-if="item.studyClass"
                :to="{
                  name: 'Career',
                  params: { id: $route.params.id, career_id: item.id },
                }"
                >{{ item.studyClass.name }}
              </router-link>
              <span v-else>{{ item.name }}</span>
            </template>

            <template v-slot:[`studyClass.studyDuration.name`]="{ item }">
              <span v-if="item.studyClass">{{ item.studyClass.studyDuration.name }} </span>
              <span v-else>{{ $t("options.career.type.S") }}</span>
            </template>

            <template v-slot:form="{ selected_id, close, duplicating }">
              <career-form
                :career_id="selected_id"
                :baseItem="{ student }"
                @cancel="close"
                @save="close"
                :duplicating="duplicating"
                @created="(newElement) => careerCreated(newElement, close)"
              />
            </template>
          </crud-table>
        </v-card>
        <v-skeleton-loader type="card" v-else rounded="lg" />
        <br />
        <v-card v-if="!loading" class="overflow-hidden" rounded="lg">
          <degrees-table
            :baseItem="{ student_id: student.id }"
            :title="$tc('models.degree', 2)"
            :degrees="loading ? [] : student.degrees"
            @degreeCreated="degreeCreated"
            @deleteDegree="deleteDegree"
          />
        </v-card>
        <br />
        <v-card v-if="!loading" class="overflow-hidden" rounded="lg">
          <abroad-experiences-table :title="$tc('models.abroadExperience', 2)" :student="student" />
        </v-card>
        <v-skeleton-loader type="card" v-else rounded="lg" />
      </v-col>
      <v-col cols="12">
        <v-card class="overflow-hidden" rounded="lg">
          <v-card-title class="align-content-start">
            <v-select
              :items="student ? student.studentYears : []"
              :loading="loading"
              item-text="year.name"
              item-value="id"
              v-model="selectedStudentYear"
              return-object
              :label="$t('student.select_student_year')"
            ></v-select>
            <v-btn v-if="selectedStudentYear" icon text @click="openStudentYearDialog(selectedStudentYear.id)"
              ><v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon text @click="openStudentYearDialog(null)"><v-icon>mdi-plus-circle-outline</v-icon> </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <br />
    <student-year-section v-if="me && me.type != 'teacher' && selectedStudentYear" :studentYear="selectedStudentYear" />

    <student-year-academic v-else-if="me && selectedStudentYear" :student_year_id="parseInt(selectedStudentYear.id)" />

    <v-dialog v-model="newStudentYearDialog" max-width="800px" persistent>
      <student-year-residence-form
        :student_year_id="student_year_edit_id"
        :baseItem="student_year_edit_id ? {} : baseItem"
        @cancel="newStudentYearDialog = false"
        @created="studentYearCreated"
        @save="newStudentYearDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { UPDATE_STUDENT } from "@/graphql/mutations";

import StudentYearSection, { STUDENT_YEAR_SECTION_FRAGMENT } from "./profile/StudentYearSection.vue";

import { addToCacheArray, deleteModel } from "../apollo/helpers";

import moment from "moment";
import gql from "graphql-tag";
import fragments from "../graphql/fragments";
import StudentAnagraphicForm from "./forms/StudentAnagraphicForm.vue";
import EditCard from "./base/EditCard.vue";
import CareerForm from "./forms/CareerForm.vue";
import StudentYearResidenceForm from "./forms/StudentYearResidenceForm.vue";
import StudentYearAcademic from "./profile/StudentYearAcademic.vue";
import CrudTable from "./base/CrudTable.vue";
import DegreesTable, { DEGREES_TABLE_FRAGMENT } from "./tables/DegreesTable.vue";
import AbroadExperiencesTable, { ABROAD_EXPERIENCES_TABLE_FRAGMENT } from "./tables/AbroadExperiencesTable.vue";
import VerifyButton from "./profile/VerifyButton.vue";

const STUDENT_INFO = gql`
  query StudentInfo($id: ID!) {
    student(id: $id) {
      ...student

      canVerifyAnagraphicProfile
      anagraphic_profile_verified_at
      anagraphicProfileVerifiedBy {
        id
        complete_name
      }

      other_nationality
      country_of_birth
      civic_number

      id_card_type
      id_card_expiration_date

      lastCollegeYear

      canUpdate
      canCreateCareers

      degrees {
        ...degreesTable
      }

      careers {
        badge_number
        ...career
      }

      user {
        id
        client {
          id
        }
      }

      studentYears {
        id
        ...studentYearSection
        year {
          id
          name
        }
      }

      ...abroadExperiencesTable
    }
  }
  ${DEGREES_TABLE_FRAGMENT}
  ${STUDENT_YEAR_SECTION_FRAGMENT}
  ${ABROAD_EXPERIENCES_TABLE_FRAGMENT}
  ${fragments.student}
  ${fragments.career}
`;

export default {
  components: {
    CrudTable,
    StudentYearSection,
    StudentAnagraphicForm,
    EditCard,
    CareerForm,
    StudentYearResidenceForm,
    StudentYearAcademic,
    DegreesTable,
    AbroadExperiencesTable,
    VerifyButton,
  },

  props: {
    student_id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      studentYear: null,
      newStudentYearDialog: false,
      moment,
      student_year_edit_id: null,
      verifing: false,
    };
  },
  apollo: {
    student: {
      query: STUDENT_INFO,
      variables() {
        return {
          id: this.student_id,
        };
      },
    },
    me: gql`
      query {
        me {
          id
          type
        }
      }
    `,
  },
  computed: {
    baseItem() {
      return { student: this.student, college_year: (this.student?.lastCollegeYear ?? 0) + 1 };
    },
    loading() {
      return this.$apollo.queries.student.loading;
    },
    selectedStudentYear: {
      get() {
        if (this.$route.params.student_year_id == null) return null;
        return this.student?.studentYears.find((studentYear) => studentYear.id == this.$route.params.student_year_id);
      },
      set(value) {
        if (value == null) this.$router.push({ name: "UserProfile" });
        else
          this.$router.push({
            name: "UserProfileYear",
            params: {
              ...this.$route.params,
              id: this.student.user.id,
              student_year_id: value.id,
            },
          });
      },
    },
    studentAnagraphicInfo() {
      const fields = [
        { field: "gender", type: "autocomplete" },
        { field: "birthday", type: "date" },
        { field: "country_of_birth" },
        { field: "born_in" },
        { field: "born_in_province" },
        { field: "nationality", type: "nationality" },
        { field: "other_nationality", type: "nationality" },
        { field: "province" },
        { field: "city" },
        { field: "cap" },
        { field: "address" },
        { field: "civic_number" },
        { field: "cf" },
        { field: "id_card_type", type: "autocomplete" },
        { field: "id_card_number" },
        { field: "id_card_expiration_date", type: "date" },
        { divider: true, cols: 12 },
        { field: "idCard", type: "file" },
        { field: "privacy", type: "file" },
        { field: "cv", type: "file" },
      ];

      return fields;
    },
  },
  methods: {
    studentYearCreated(newStudentYear) {
      this.$apollo.queries.student.refetch().then(() => {
        this.selectedStudentYear = newStudentYear;
        this.newStudentYearDialog = false;
      });
    },

    save({ id, ...input }) {
      input.files = { create: [] };

      if (input.privacy) {
        input.files.create.push({
          name: "privacy",
          file: input.privacy,
        });
      }

      if (input.idCard) {
        input.files.create.push({
          name: "idCard",
          file: input.idCard,
        });
      }

      delete input.idCard;
      delete input.privacy;

      this.$apollo.mutate({
        mutation: UPDATE_STUDENT,
        optimisticResponse: {
          updateStudent: { ...this.student, id, ...input },
        },
        variables: { id, input },
      });
    },

    degreeCreated(newElement) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: STUDENT_INFO,
          variables: {
            id: this.student_id,
          },
        },
        queryField: "student.degrees",
        newElement,
      });
    },

    deleteDegree({ id }) {
      deleteModel(this.$apollo, {
        id,
        model: "Degree",
        query: { query: STUDENT_INFO, variables: { id: this.student_id } },
        queryField: "student.degrees",
      });
    },

    careerCreated(newElement, close) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: STUDENT_INFO,
          variables: {
            id: this.student_id,
          },
        },
        queryField: "student.careers",
        newElement,
      });
      close();
    },

    deleteCareer({ id }) {
      deleteModel(this.$apollo, {
        id,
        model: "Career",
        query: { query: STUDENT_INFO, variables: { id: this.student_id } },
        queryField: "student.careers",
      });
    },

    openStudentYearDialog(id) {
      this.student_year_edit_id = id;
      this.newStudentYearDialog = true;
    },

    verifyAnagraphicProfile() {
      this.verifing = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation VerifyAnagraphicProfile($id: ID!) {
              verifyAnagraphicProfile(id: $id) {
                id
                anagraphic_profile_verified_at
                anagraphicProfileVerifiedBy {
                  id
                  complete_name
                }
              }
            }
          `,
          variables: { id: this.student.id },
        })
        .finally(() => (this.verifing = false));
    },
  },
};
</script>
