<template>
  <div>
    <v-row v-if="!$apollo.queries.studentYear.loading">
      <v-col cols="12">
        <edit-card
          :item="studentYear"
          model="studentYear"
          :title="$tc('profile.residence_info', 1)"
          @save="updateStudentYear"
          :info="residenceFields"
          grow
          :hideEmptyFields="false"
        >
          <template v-slot:form="{ close }">
            <student-year-residence-form :student_year_id="studentYear.id" @cancel="close" @save="close" />
          </template>

          <template #action>
            <VerifyButton
              @verify="verifyResidenceInfo"
              :verifing="verifing_residence_info"
              :verified-at="studentYear.residence_info_verified_at"
              :verified-by="studentYear.residenceInfoVerifiedBy"
              :can-verify="studentYear.canVerifyResidenceInfo"
            />
          </template>
        </edit-card>
      </v-col>
      <v-col cols="12">
        <edit-card
          :item="studentYear"
          model="studentYear"
          :title="$t('studentYear.tuition_section')"
          @save="updateStudentYear"
          :info="visibleAdministrationInfo"
          grow
          :hideEmptyFields="false"
        >
          <template v-slot:form="{ close }">
            <student-year-administration-form :student_year_id="studentYear.id" @cancel="close" @save="close" />
          </template>

          <template #action>
            <VerifyButton
              @verify="verifyTuition"
              :verifing="verifing_tuition"
              :verified-at="studentYear.tuition_verified_at"
              :verified-by="studentYear.tuitionVerifiedBy"
              :can-verify="studentYear.canVerifyTuition"
            />
          </template>
        </edit-card>
      </v-col>
      <v-col cols="12">
        <v-card rounded="lg" class="overflow-hidden">
          <payments-table
            :payable="studentYear"
            :title="$tc('models.payment', 2)"
            :items="studentYear.payments"
            :baseItem="{ studentYear }"
          />
        </v-card>
      </v-col>
      <v-col cols="12">
        <student-year-documents :studentYear="studentYear" @save="updateStudentYear" />
      </v-col>
      <v-col cols="12">
        <v-btn @click="deleteDialog = true" block dark color="danger">{{ $t("delete") }}</v-btn>
      </v-col>

      <delete-dialog v-model="deleteDialog" @confirm="deleteStudentYear" />
    </v-row>
    <v-skeleton-loader type="card" v-else />
  </div>
</template>

<script>
import gql from "graphql-tag";
import StudentYearDocuments, { STUDENT_YEAR_DOCUMENTS_FRAGMENT } from "./StudentYearDocuments.vue";
import { deleteModelMutation, updateMutationBuilder } from "../../graphql/mutations";
import { removeFromCacheFragmentArray } from "../../apollo/helpers";
import EditCard from "../base/EditCard.vue";
import StudentYearAdministrationForm, {
  BASE_STUDENT_YEAR_ADMINISTRATION_FRAGMENT,
} from "../forms/StudentYearAdministrationForm.vue";
import StudentYearResidenceForm from "../forms/StudentYearResidenceForm.vue";
import DeleteDialog from "../DeleteDialog.vue";
import { STUDENT_STUDENT_YEARS_FRAGMENT } from "../../graphql/fragments";
import PaymentsTable, { STUDENT_YEAR_PAYMENTS_TABLE_FRAGMENT } from "../tables/PaymentsTable.vue";
import moment from "moment";
import VerifyButton from "./VerifyButton.vue";

const STUDENT_YEAR_ADMINISTRATION_FRAGMENT = gql`
  fragment studentYearAdministration on StudentYear {
    id
    canUpdate: canUpdateAdministration
    ...studentYearDocuments

    type

    residence_start
    residence_end
    residence_time
    college_year

    contract_time
    residence_abroad_time
    residence_time_difference

    family_count
    isr
    isp
    ise
    attributions
    s_eq

    isee
    iseeWithAttributions

    inps_reduction
    other_reductions

    type

    residence_start
    residence_end

    residence_time

    contract_time
    residence_abroad_time
    residence_time_difference

    residence_break_start
    residence_break_end
    residence_break_time
    residence_break_reason

    room_rate
    services_cost

    effective_max_tuition
    effective_education_bonus
    effective_isee_reduction
    total_reductions

    notes

    tuition
    tuitionFormula {
      id
      name
      formula
    }

    canVerifyTuition
    tuition_verified_at
    tuitionVerifiedBy {
      id
      complete_name
    }

    canVerifyResidenceInfo
    residence_info_verified_at
    residenceInfoVerifiedBy {
      id
      complete_name
    }

    tuitionRange {
      id
      name
      isee_reduction
    }

    tuitionFormula {
      id
      formula
    }

    student {
      id
    }

    residence {
      id
      name
      area {
        id
      }
    }

    chamber {
      id
      name
    }

    residenceYear {
      id
      max_tuition
      education_bonus
    }

    student {
      id
    }

    ...studentYearPaymentsTable
  }
  ${STUDENT_YEAR_DOCUMENTS_FRAGMENT}
  ${STUDENT_YEAR_PAYMENTS_TABLE_FRAGMENT}
`;

export default {
  components: {
    StudentYearDocuments,
    EditCard,
    StudentYearAdministrationForm,
    StudentYearResidenceForm,
    DeleteDialog,
    PaymentsTable,
    VerifyButton,
  },

  name: "StudentYearAdministration",

  props: {
    student_year_id: Number,
  },

  apollo: {
    studentYear: {
      query: gql`
        query StudentYearAdministration($id: ID!) {
          studentYear(id: $id) {
            id
            ...studentYearAdministration
          }
        }
        ${STUDENT_YEAR_ADMINISTRATION_FRAGMENT}
      `,
      variables() {
        return { id: this.student_year_id };
      },
    },
  },

  data() {
    return {
      deleteDialog: false,
      verifing_tuition: false,
      verifing_residence_info: false,
    };
  },
  computed: {
    visibleAdministrationInfo() {
      return this.studentYearInfo.filter((field) => !field.isVisible || field.isVisible());
    },

    residenceFields() {
      return [
        { field: "type", type: "autocomplete" },
        { field: "residence.name", label: this.$t("residence.name") },
        { field: "chamber.name", label: this.$t("chamber.name") },

        { field: "residence_start", type: "date", cols: 4 },
        { field: "residence_end", type: "date", cols: 4 },
        { field: "residence_time", cols: 4 },

        { field: "residence_break_reason", cols: 12, type: "autocomplete", hideIfEmpty: true },

        { field: "residence_break_start", type: "date", cols: 4, hideIfEmpty: true },
        { field: "residence_break_end", type: "date", cols: 4, hideIfEmpty: true },
        { field: "residence_break_time", cols: 4, hideIfEmpty: true },

        { field: "college_year" },
      ];
    },

    studentYearInfo() {
      return [
        { field: "contract_time" },
        { field: "residence_abroad_time" },
        { field: "residence_time_difference" },
        {
          divider: true,
          cols: 12,
        },
        {
          field: "family_count",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },
        {
          field: "isr",
          type: "currency",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },
        {
          field: "isp",
          type: "currency",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },
        {
          field: "ise",
          type: "currency",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },
        {
          field: "s_eq",
          type: "currency",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },
        {
          field: "isee",
          type: "currency",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },

        {
          field: "attributions",
          type: "currency",
          cols: 6,
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },
        {
          field: "iseeWithAttributions",
          type: "currency",
          cols: 6,
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },

        {
          field: "tuitionRange.name",
          label: this.$t("tuitionRange.name"),
          cols: 12,
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },

        {
          divider: true,
          cols: 12,
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },

        {
          field: "tuitionFormula.name",
          cols: 12,
          label: this.$t("tuitionFormula.name"),
        },
        {
          field: "effective_isee_reduction",
          label: this.$t("tuitionRange.isee_reduction"),
          type: "currency",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },

        {
          field: "room_rate",
          type: "currency",
          cols: 6,
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("room_rate"),
        },
        {
          field: "services_cost",
          type: "currency",
          cols: 6,
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("services_cost"),
        },
        {
          field: "inps_reduction",
          type: "number",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("inps_reduction"),
        },
        {
          field: "other_reductions",
          type: "currency",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("other_reductions"),
        },

        {
          field: "effective_max_tuition",
          label: this.$t("residenceYear.max_tuition"),
          type: "currency",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },
        {
          field: "effective_education_bonus",
          label: this.$t("residenceYear.education_bonus"),
          type: "currency",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("education_bonus"),
        },
        {
          field: "total_reductions",
          type: "currency",
          isVisible: () => this.studentYear.tuitionFormula?.formula.includes("isee_reduction"),
        },

        { field: "tuition", type: "currency", cols: 12, highlight: true },

        { divider: true, cols: 12 },
        { field: "notes", cols: 12 },
      ];
    },
  },
  methods: {
    moment: moment,
    deleteStudentYear() {
      this.$apollo
        .mutate({
          mutation: deleteModelMutation("StudentYear"),
          variables: { id: this.studentYear.id },
          update: (store) =>
            removeFromCacheFragmentArray(store, {
              fragment_info: {
                id: `Student:${this.studentYear.student.id}`,
                fragment: STUDENT_STUDENT_YEARS_FRAGMENT,
                fragmentName: "studentStudentYears",
              },
              array_path: "studentYears",
              id: this.studentYear.id,
            }),
        })
        .then(() => {
          this.$router.replace({ name: "UserProfile", params: { id: this.$route.params.id } });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateStudentYear({ id, ...input }) {
      this.$apollo.mutate({
        mutation: updateMutationBuilder({
          model: "StudentYear",
          fragment: BASE_STUDENT_YEAR_ADMINISTRATION_FRAGMENT,
          fragment_name: "baseStudentYearAdministration",
        }),
        variables: {
          id,
          input,
        },
        optimisticResponse: {
          updateStudentYear: { ...this.studentYear, ...input },
        },
      });
    },

    verifyTuition() {
      this.verifing_tuition = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation VerifyTuition($id: ID!) {
              verifyTuition(id: $id) {
                id
                tuition_verified_at
                tuitionVerifiedBy {
                  id
                  complete_name
                }
              }
            }
          `,
          variables: { id: this.student_year_id },
        })
        .finally(() => (this.verifing_tuition = false));
    },

    verifyResidenceInfo() {
      this.verifing_residence_info = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation VerifyResidenceInfo($id: ID!) {
              verifyResidenceInfo(id: $id) {
                id
                residence_info_verified_at
                residenceInfoVerifiedBy {
                  id
                  complete_name
                }
              }
            }
          `,
          variables: { id: this.student_year_id },
        })
        .finally(() => (this.verifing_residence_info = false));
    },
  },
};
</script>
