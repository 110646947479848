var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mb-0"},[_c('v-col',{staticClass:"d-flex align-end justify-center justify-sm-start",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"small":"","link":"","text":"","to":{
        name: 'Users',
      }}},[_c('v-icon',[_vm._v("mdi-arrow-left-circle-outline")]),_c('span',{staticClass:"ml-1 text-uppercase"},[_vm._v(_vm._s(_vm.$tc("models.user", 2)))])],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-center justify-center justify-sm-end",attrs:{"cols":"12","sm":"auto"}},[(_vm.previousUser)?_c('v-btn',{attrs:{"small":"","link":"","text":"","to":{
        name: 'UserProfile',
        params: { id: _vm.previousUser.id },
      }}},[_c('v-icon',[_vm._v("mdi-arrow-left-circle-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.previousUser.complete_name))])],1):_c('v-btn',{attrs:{"small":"","link":"","text":"","disabled":""}},[_c('v-icon',[_vm._v("mdi-arrow-left-circle-outline")])],1),(_vm.nextUser)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","link":"","text":"","to":{
        name: 'UserProfile',
        params: { id: _vm.nextUser.id },
      }}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.nextUser.complete_name))]),_c('v-icon',[_vm._v("mdi-arrow-right-circle-outline")])],1):_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","link":"","text":"","disabled":""}},[_c('v-icon',[_vm._v("mdi-arrow-right-circle-outline")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }