<template>
  <base-table
    :title="$tc('models.abroadExperience', 2)"
    :items="student.abroadExperiences"
    :headers="headers"
    :tableProps="{ dense: true }"
    @deleteItem="deleteAbroadExperience"
  >
    <template v-slot:form="{ selected_id, close, duplicating }">
      <abroad-experience-form
        :abroad_experience_id="selected_id"
        :baseItem="{ student }"
        :duplicating="duplicating"
        @cancel="close"
        @updated="close"
        @created="(abroadExperience) => abroadExperienceCreated(abroadExperience, close)"
      />
    </template>
  </base-table>
</template>

<script>
export const ABROAD_EXPERIENCE_FRAGMENT = gql`
  fragment abroadExperience on AbroadExperience {
    id
    type
    country
    university
    start_date
    end_date
    days
    abroad_certification: file(name: "abroad_certification") {
      id
      name
      link
    }
  }
`;

export const ABROAD_EXPERIENCES_TABLE_FRAGMENT = gql`
  fragment abroadExperiencesTable on Student {
    id
    abroadExperiences {
      ...abroadExperience
    }
  }

  ${ABROAD_EXPERIENCE_FRAGMENT}
`;

import BaseTable from "./BaseTable.vue";
import AbroadExperienceForm from "../forms/AbroadExperienceForm.vue";
import gql from "graphql-tag";
import { addToCacheFragmentArray, removeFromCacheFragmentArray } from "../../apollo/helpers";

export default {
  components: { BaseTable, AbroadExperienceForm },
  name: "AbroadExperiencesTable",
  props: {
    student: Object,
  },

  data() {
    return {
      headers: [
        { value: "type", text: this.$t("abroadExperience.type") },
        { value: "country", text: this.$t("abroadExperience.country") },
        { value: "university", text: this.$t("abroadExperience.university") },
        { value: "start_date", text: this.$t("abroadExperience.start_date"), type: "date" },
        { value: "end_date", text: this.$t("abroadExperience.end_date"), type: "date" },
        { value: "days", text: this.$t("abroadExperience.days") },
        { value: "abroad_certification", text: this.$t("abroadExperience.abroad_certification"), type: "file" },
      ],
    };
  },

  methods: {
    abroadExperienceCreated(abroadExperience, close) {
      addToCacheFragmentArray(this.$apollo.provider.defaultClient, {
        fragment_info: {
          fragment: ABROAD_EXPERIENCES_TABLE_FRAGMENT,
          fragmentName: "abroadExperiencesTable",
          id: `Student:${this.student.id}`,
        },
        array_path: "abroadExperiences",
        newElement: abroadExperience,
      });
      close();
    },

    deleteAbroadExperience({ id }) {
      removeFromCacheFragmentArray(this.$apollo.provider.defaultClient, {
        fragment_info: {
          fragment: ABROAD_EXPERIENCES_TABLE_FRAGMENT,
          fragmentName: "abroadExperiencesTable",
          id: `Student:${this.student.id}`,
        },
        array_path: "abroadExperiences",
        id,
      });
    },
  },
};
</script>
