<template>
  <base-form
    model="student"
    @save="student_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.student.loading"
    :currentValue="student"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
export const BASE_STUDENT_ANAGRAPHIC_FRAGMENT = gql`
  fragment baseStudentAnagraphic on Student {
    id
    gender
    cf
    birthday
    country_of_birth
    born_in
    born_in_province
    nationality
    address
    city
    province
    cap
    id_card_type
    id_card_number
    id_card_expiration_date

    anagraphic_profile_verified_at
    anagraphicProfileVerifiedBy {
      id
      complete_name
    }

    nationality
    other_nationality
    civic_number

    idCard: file(name: "idCard") {
      id
      name
      link
    }
    privacy: file(name: "privacy") {
      id
      name
      link
    }
    cv: file(name: "cv") {
      id
      name
      link
    }
  }
`;

import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";

export default {
  name: "StudentAnagraphicForm",
  components: { BaseForm },

  props: {
    student_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    student_id(val) {
      if (val == null) this.student = {};
    },
  },

  data() {
    return {
      newValue: {},
      saving: false,
      student: {},
      fields: [
        {
          name: "gender",
          type: "autocomplete",
          items: ["F", "M"].map((value) => ({
            text: this.$t(`options.student.gender.${value}`),
            value,
          })),
        },
        { name: "birthday", type: "date" },
        {
          name: "country_of_birth",
          type: "api",
          path: "countries",
          query: gql`
            query Countries {
              countries {
                id
                name
              }
            }
          `,
          cols: 12,
        },
        {
          name: "born_in",
        },
        {
          name: "born_in_province",
          type: "api",
          path: "provinces",
          allow_unknown: true,
          query: gql`
            query Provinces {
              provinces
            }
          `,
          bind: {
            clearable: true,
          },
        },
        {
          name: "nationality",
          type: "api",
          path: "countries",
          query: gql`
            query Countries {
              countries {
                id
                name
              }
            }
          `,
        },
        {
          name: "other_nationality",
          type: "api",
          path: "countries",
          query: gql`
            query Countries {
              countries {
                id
                name
              }
            }
          `,
        },
        {
          name: "province",
          type: "api",
          path: "provinces",
          allow_unknown: true,
          query: gql`
            query Provinces {
              provinces
            }
          `,
        },
        { name: "city" },
        { name: "cap" },
        { name: "address", cols: 9 },
        { name: "civic_number", cols: 3 },
        { name: "cf" },
        {
          name: "id_card_type",
          type: "autocomplete",
          items: ["id_card", "driving_license", "passport", "residency_permit"].map((value) => ({
            text: this.$t(`options.student.id_card_type.${value}`),
            value,
          })),
        },
        { name: "id_card_number" },
        { name: "id_card_expiration_date", type: "date" },
        { name: "idCard", type: "file" },
        { name: "privacy", type: "file" },
        { name: "cv", type: "file" },
      ],
    };
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Student",
            fragment: BASE_STUDENT_ANAGRAPHIC_FRAGMENT,
            fragment_name: "baseStudentAnagraphic",
          }),
          variables: {
            id: this.student_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Student",
            fragment: BASE_STUDENT_ANAGRAPHIC_FRAGMENT,
            fragment_name: "baseStudentAnagraphic",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createStudent } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createStudent);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },

  apollo: {
    student: {
      query: gql`
        query Student($id: ID!) {
          student(id: $id) {
            ...baseStudentAnagraphic
          }
        }
        ${BASE_STUDENT_ANAGRAPHIC_FRAGMENT}
      `,
      variables() {
        return {
          id: this.student_id,
        };
      },
      skip() {
        return this.student_id == null;
      },
    },
  },
};
</script>
