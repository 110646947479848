import { render, staticRenderFns } from "./DegreesTable.vue?vue&type=template&id=bccc8c78"
import script from "./DegreesTable.vue?vue&type=script&lang=js"
export * from "./DegreesTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports