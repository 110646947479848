<template>
  <base-table
    no_duplicates
    :title="title"
    :items="degrees"
    :headers="headers"
    v-bind="tableProps"
    :tableProps="{ dense: true }"
    @deleteItem="deleteDegree"
  >
    <template v-slot:form="{ selected_id, close, duplicating }">
      <degree-form
        :degree_id="selected_id"
        :baseItem="baseItem"
        @cancel="close"
        :duplicating="duplicating"
        @save="(degree) => degreeUpdated(degree, close)"
        @created="(degree) => degreeCreated(degree, close)"
      />
    </template>
  </base-table>
</template>

<script>
export const DEGREES_TABLE_FRAGMENT = gql`
  fragment degreesTable on Degree {
    id
    name
    grade
    place
    date
    attachment: file(name: "attachment") {
      id
      link
    }
  }
`;

import BaseTable from "./BaseTable.vue";
import DegreeForm from "../forms/DegreeForm.vue";
import gql from "graphql-tag";

export default {
  components: { BaseTable, DegreeForm },
  name: "DegreesTable",
  props: {
    title: String,
    degrees: Array,
    baseItem: { type: Object, default: () => ({}) },
    tableProps: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      headers: [
        { value: "name", text: this.$t("degree.name") },
        { value: "grade", text: this.$t("degree.grade") },
        { value: "date", text: this.$t("degree.date"), type: "date" },
        { value: "place", text: this.$t("degree.place") },
        { value: "attachment", text: this.$t("degree.attachment"), type: "file" },
      ],
    };
  },

  methods: {
    degreeUpdated(degree, close) {
      this.$emit("degreeUpdated", degree);
      close();
    },

    degreeCreated(degree, close) {
      this.$emit("degreeCreated", degree);
      close();
    },

    deleteDegree(degree) {
      this.$emit("deleteDegree", degree);
    },
  },
};
</script>