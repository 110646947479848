<template>
  <v-card class="overflow-hidden" rounded="lg">
    <div v-if="!$apollo.queries.studentYear.loading">
      <v-toolbar :elevation="0">
        <v-toolbar-title class="text-capitalize d-flex">
          {{ $t("transcript") }}
        </v-toolbar-title>
        <v-spacer />
        <v-icon :disabled="page <= 1" class="mx-2" @click="page--">mdi-chevron-left</v-icon>
        {{ page }}
        <v-icon :disabled="page >= numPages" class="mx-2" @click="page++">mdi-chevron-right</v-icon>
        <v-spacer />
        <v-btn color="primary" @click="download">{{ $t("download") }}</v-btn>
      </v-toolbar>
      <v-card-text>
        <pdf :page="page" ref="transcript" :src="pdfUri" @num-pages="(num) => (numPages = num)">
          <template slot="loading"> </template>
        </pdf>
      </v-card-text>
    </div>
    <div class="w-100 text-center" v-else>
      <v-progress-circular color="primary" class="ma-4" indeterminate :size="50" />
    </div>
  </v-card>
</template>

<script>
const TRANSCRIPT_INFO = gql`
  query TranscriptInfo($student_year_id: ID!) {
    studentYear(id: $student_year_id) {
      id
      goal_time
      total_presences
      type
      contract_time
      studentYearTag {
        id
        name
        transcriptSettings {
          header_text
          footer_text
          show_student_year_tag
          fill_logo_color
          logo {
            id
            name
            link
          }
          footerLogo {
            id
            name
            link
          }
        }
      }
      residence {
        id
        name
      }
      year {
        id
        name
      }
      student {
        id
        cf
        user {
          id
          username
          name
          surname
          client {
            id
            name
          }
        }
      }
      compactPresences {
        course {
          id
          name
        }
        type
        argument
        total
        teacher
        presences_count
      }
    }
  }
`;

import { PDF, docWidth } from "@/maia/pdf";

import pdf from "vue-pdf";
import gql from "graphql-tag";

export default {
  name: "Transcript",
  components: { pdf },
  props: {
    student_year_id: {
      required: true,
    },
  },

  apollo: {
    studentYear: {
      query: TRANSCRIPT_INFO,
      variables() {
        return {
          student_year_id: this.student_year_id,
        };
      },
    },
  },

  data() {
    return {
      pdfUri: null,
      numPages: 1,
      page: 1,
    };
  },

  methods: {
    formatTime(decimalTime) {
      if (decimalTime == null) return "-";

      let hours = Math.floor(decimalTime);
      let minutes = Math.round((decimalTime - hours) * 60);

      return `${hours}h ${minutes}m`;
    },

    /**
     *
     * @param {Object} user User object
     * @returns {String} URI string of pdf
     */
    createTranscriptPDF(studentYear) {
      let tableArray = [];

      //595 pt x 842 pt
      var doc = new PDF(
        studentYear.student.cf || studentYear.student.user.username,
        studentYear.studentYearTag.transcriptSettings
      );

      doc.setFont("helvetica");
      doc.setFontType("bold");

      doc.setFontSize(20);

      const main_color =
        studentYear.studentYearTag.transcriptSettings.fill_logo_color ?? this.$vuetify.theme.currentTheme.primary;
      doc.setTextHexColor(main_color);

      doc.printCenteredText("LIBRETTO FORMATIVO DELLO STUDENTE");

      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      doc.setFontType("normal");
      doc.printCenteredText(`${studentYear.student.user.client.name} - Residenza ${studentYear.residence.name}`);
      doc.printCenteredText(`Anno accademico ${studentYear.year.name}`);

      studentYear.compactPresences.forEach((compactPresence) => {
        const { type, argument, teacher, total, presences_count } = compactPresence;
        tableArray.push([
          argument,
          type ? this.$t(`options.event.type.${type}`) : "-",
          teacher,
          +(total / 3600.0).toFixed(2),
          presences_count,
        ]);
      });

      let total_string =
        parseInt(studentYear.total_presences / 3600).toString() +
        "h " +
        ((studentYear.total_presences / 60) % 60).toString() +
        "m";

      doc.setFontSize(12);
      doc.setFontType("normal");

      doc.printLineText("");

      doc.printLineText("Dati studente:");

      const head = [["Cognome e nome", "Codice fiscale", "Tipologia", "Ore di formazione"]];

      const body = [
        [
          `${studentYear.student.user.surname ?? ""} ${studentYear.student.user.name ?? ""}`,
          studentYear.student.cf ?? "-",
          this.$t(`options.studentadministration.type.${studentYear.type ?? "none"}`),
          `${total_string} / ${this.formatTime(studentYear.goal_time)}`,
        ],
      ];

      if (studentYear.studentYearTag.transcriptSettings.show_student_year_tag) {
        head[0].push("TAG studente");
        body[0].push(studentYear.studentYearTag?.name ?? "-");
      }

      doc.autoTable({
        head,
        body,
        headStyles: { halign: "center", fillColor: main_color },
        columnStyles: {
          0: { halign: "center" },
          1: { halign: "center" },
          2: { halign: "center" },
          3: { halign: "center" },
          4: { halign: "center" },
          5: { halign: "center" },
        },
      });

      doc.printLineText("Attività svolte:");

      doc.autoTable({
        head: [["Argomento", this.$t("event.type"), "Docente", "Ore", "Presenze"]],
        body: tableArray,
        headStyles: { halign: "center", fillColor: main_color },
        columnStyles: {
          0: { cellWidth: docWidth / 4, valign: "middle" },
          1: { cellWidth: docWidth / 5, halign: "center", valign: "middle" },
          2: { cellWidth: docWidth / 5, halign: "center", valign: "middle" },
          3: { halign: "center", valign: "middle" },
          4: { halign: "center", valign: "middle" },
        },
      });

      return doc.output();
    },

    download() {
      var link = document.createElement("a");
      link.href = this.pdfUri;
      link.download = this.studentYear.student.user.cf || this.studentYear.student.user.username;
      link.dispatchEvent(new MouseEvent("click"));
    },
  },

  watch: {
    async studentYear(val) {
      if (val != null) this.pdfUri = await this.createTranscriptPDF(this.studentYear);
    },
  },
};
</script>
