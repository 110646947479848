<template>
  <div id="student-year">
    <v-card rounded="lg" class="overflow-hidden">
      <v-tabs grow :value="tab" @change="changeTab">
        <v-tab key="1" href="#1" v-if="studentYear.studentYearTag?.has_academic_profile && showAcademic">{{
          $tc("models.studentacademic", 1)
        }}</v-tab>
        <v-tab key="2" href="#2" v-if="studentYear.studentYearTag?.has_education_profile">{{
          $tc("models.studenteducation", 1)
        }}</v-tab>
        <v-tab key="3" href="#3" v-if="studentYear.studentYearTag?.has_transcript">{{ $t("transcript") }}</v-tab>
        <v-tab
          key="4"
          href="#4"
          v-if="studentYear.studentYearTag?.has_administration_profile && studentYear.canViewAdministration"
          >{{ $tc("models.studentadministration", 1) }}</v-tab
        >
        <v-tab key="5" href="#5" v-if="studentYear.canViewAnnotations">{{ $tc("studentYear.notes", 1) }}</v-tab>
      </v-tabs>
    </v-card>

    <v-window style="min-height: 50vh" class="py-4" :value="tab">
      <v-window-item value="1">
        <student-year-academic v-if="showAcademic" :student_year_id="parseInt(studentYear.id)" />
      </v-window-item>
      <v-window-item value="2">
        <student-year-education :student_year_id="parseInt(studentYear.id)" />
      </v-window-item>
      <v-window-item value="3">
        <v-container> <transcript :student_year_id="parseInt(studentYear.id)" /></v-container>
      </v-window-item>
      <v-window-item value="4" v-if="studentYear.canViewAdministration">
        <student-year-administration :student_year_id="parseInt(studentYear.id)" />
      </v-window-item>
      <v-window-item value="5" v-if="studentYear.canViewAnnotations">
        <student-year-annotations :student_year_id="parseInt(studentYear.id)" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import Transcript from "./Transcript.vue";
import StudentYearAdministration from "./StudentYearAdministration.vue";
import StudentYearEducation from "./StudentYearEducation.vue";
import StudentYearAcademic from "./StudentYearAcademic.vue";
import StudentYearAnnotations from "./StudentYearAnnotations.vue";
import gql from "graphql-tag";

export const STUDENT_YEAR_SECTION_FRAGMENT = gql`
  fragment studentYearSection on StudentYear {
    id
    type
    studentYearTag {
      id
      has_academic_profile
      has_education_profile
      has_administration_profile
      has_transcript
    }
    canViewAdministration
    canViewAnnotations
  }
`;

export default {
  components: {
    Transcript,
    StudentYearAdministration,
    StudentYearEducation,
    StudentYearAcademic,
    StudentYearAnnotations,
  },
  name: "StudentYearSection",

  props: {
    studentYear: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      me: { type: "student" },
    };
  },

  computed: {
    showAcademic() {
      return ["S", "E"].includes(this.studentYear.type);
    },

    tab() {
      return this.$route.params.tab ?? 0;
    },
  },

  mounted() {
    this.scroll();
  },

  methods: {
    changeTab(value) {
      if (this.tab === value) return;
      this.$router.replace({
        name: "UserProfileYearTab",
        params: { ...this.$route.params, tab: value },
        hash: "#student-year",
      });
    },

    scroll() {
      if (this.$route.hash)
        document.getElementById(this.$route.hash?.slice(1))?.scrollIntoView({
          behavior: "smooth",
        });
    },
  },
};
</script>

<style></style>
