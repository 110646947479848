<template>
  <base-form
    model="studentYear"
    @save="student_year_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.studentYear.loading"
    :currentValue="studentYear"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
export const BASE_STUDENT_YEAR_ADMINISTRATION_FRAGMENT = gql`
  fragment baseStudentYearAdministration on StudentYear {
    id
    family_count
    isr
    isp
    ise
    attributions
    s_eq

    isee
    iseeWithAttributions

    inps_reduction
    other_reductions

    type

    residence_start
    residence_end

    residence_time

    contract_time
    residence_abroad_time
    residence_time_difference

    room_rate
    services_cost

    effective_isee_reduction
    effective_education_bonus
    effective_max_tuition

    total_reductions

    tuition
    tuition_verified_at
    tuitionVerifiedBy {
      id
      name
    }

    notes

    residence {
      id
      name
    }

    tuitionFormula {
      id
      name
      formula
    }

    year {
      id
    }
  }
`;

import { updateMutationBuilder } from "../../graphql/mutations";
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: { BaseForm },
  name: "StudentYearAdministrationForm",
  props: {
    student_year_id: { required: true },
    duplicating: Boolean,
  },

  watch: {
    student_year_id(val) {
      if (val == null) this.studentYear = {};
    },
  },

  apollo: {
    studentYear: {
      query: gql`
        query StudentYear($id: ID!) {
          studentYear(id: $id) {
            ...baseStudentYearAdministration
          }
        }
        ${BASE_STUDENT_YEAR_ADMINISTRATION_FRAGMENT}
      `,
      variables() {
        return {
          id: this.student_year_id,
        };
      },
      skip() {
        return this.student_year_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      studentYear: {},
      saving: false,
      fields: [
        {
          cols: 12,
          name: "tuitionFormula",
          type: "query",
          path: "year.tuitionFormulas",
          label: this.$t("tuitionFormula.name"),
          variables: (currentValue) => ({ year_id: currentValue.year.id }),
          skip: (currentValue) => !currentValue.year,
          query: gql`
            query TuitionFormulas($year_id: ID!) {
              year(id: $year_id) {
                id
                tuitionFormulas {
                  id
                  name
                  formula
                }
              }
            }
          `,
        },
        {
          name: "contract_time",
          type: "number",
          validation: {
            required,
          },
        },
        {
          name: "residence_abroad_time",
          type: "number",
          validation: {
            required,
          },
        },
        {
          name: "room_rate",
          type: "number",
          step: 0.01,
          isVisible: (currentValue) => currentValue.tuitionFormula?.formula.includes("room_rate"),
          ["append-icon"]: "mdi-currency-eur",
        },
        {
          name: "services_cost",
          type: "number",
          step: 0.01,
          isVisible: (currentValue) => currentValue.tuitionFormula?.formula.includes("services_cost"),
          ["append-icon"]: "mdi-currency-eur",
        },
        {
          name: "family_count",
          type: "number",
          isVisible: this.hasIseeReduction,
        },
        {
          name: "isr",
          type: "number",
          step: 0.01,
          isVisible: this.hasIseeReduction,
          ["append-icon"]: "mdi-currency-eur",
        },
        {
          name: "isp",
          type: "number",
          step: 0.01,
          isVisible: this.hasIseeReduction,
          ["append-icon"]: "mdi-currency-eur",
        },
        {
          name: "ise",
          type: "number",
          step: 0.01,
          isVisible: this.hasIseeReduction,
          ["append-icon"]: "mdi-currency-eur",
        },
        {
          name: "attributions",
          type: "number",
          step: 0.01,
          isVisible: this.hasIseeReduction,
          ["append-icon"]: "mdi-currency-eur",
        },
        {
          name: "s_eq",
          type: "number",
          step: 0.01,
          isVisible: this.hasIseeReduction,
        },
        {
          name: "inps_reduction",
          type: "number",
          step: 0.01,
          max: 100,
          min: 0,
          isVisible: (currentValue) => currentValue.tuitionFormula?.formula.includes("inps_reduction"),
          ["append-icon"]: "mdi-percent",
        },
        {
          name: "other_reductions",
          type: "number",
          step: 0.01,
          isVisible: (currentValue) => currentValue.tuitionFormula?.formula.includes("other_reductions"),
          ["append-icon"]: "mdi-currency-eur",
        },

        { name: "notes", type: "textarea", cols: 12 },
      ],
    };
  },

  methods: {
    hasIseeReduction(currentValue) {
      return currentValue.tuitionFormula?.formula.includes("isee_reduction");
    },

    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "StudentYear",
            fragment: BASE_STUDENT_YEAR_ADMINISTRATION_FRAGMENT,
            fragment_name: "baseStudentYearAdministration",
          }),
          variables: {
            id: this.student_year_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
