<template>
  <v-row class="mb-0">
    <v-col cols="12" sm="auto" class="d-flex align-end justify-center justify-sm-start">
      <v-btn
        small
        link
        text
        :to="{
          name: 'Users',
        }"
      >
        <v-icon>mdi-arrow-left-circle-outline</v-icon>
        <span class="ml-1 text-uppercase">{{ $tc("models.user", 2) }}</span>
      </v-btn>
    </v-col>
    <v-spacer />
    <v-col class="d-flex align-center justify-center justify-sm-end" cols="12" sm="auto">
      <v-btn
        small
        link
        text
        v-if="previousUser"
        :to="{
          name: 'UserProfile',
          params: { id: previousUser.id },
        }"
      >
        <v-icon>mdi-arrow-left-circle-outline</v-icon>
        <span class="ml-1">{{ previousUser.complete_name }}</span>
      </v-btn>
      <v-btn small link text v-else disabled>
        <v-icon>mdi-arrow-left-circle-outline</v-icon>
      </v-btn>

      <v-btn
        small
        link
        text
        class="ml-2"
        v-if="nextUser"
        :to="{
          name: 'UserProfile',
          params: { id: nextUser.id },
        }"
      >
        <span class="mr-1">{{ nextUser.complete_name }}</span>
        <v-icon>mdi-arrow-right-circle-outline</v-icon>
      </v-btn>
      <v-btn class="ml-2" small link text v-else disabled>
        <v-icon>mdi-arrow-right-circle-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    previousUser: Object,
    nextUser: Object,
  },
};
</script>
