<template>
  <div>
    <edit-card
      :info="studentAnagraphicInfo"
      v-if="!loading"
      :item="student"
      model="student"
      :title="$tc('models.studentanagraphic', 1)"
      rounded="lg"
      grow
      hideEmptyFields
    >
      <template v-slot:form="{ close }">
        <student-anagraphic-form :student_id="student.id" @cancel="close" @save="close" />
      </template>
    </edit-card>
    <v-skeleton-loader type="card" v-else rounded="lg" />
    <Submission class="mt-4" v-if="!loading && candidature.submission" :submission="candidature.submission" />
  </div>
</template>

<script>
import gql from "graphql-tag";
import StudentAnagraphicForm from "../forms/StudentAnagraphicForm.vue";
import EditCard from "../base/EditCard.vue";
import Submission, { SUBMISSION_FRAGMENT } from "../survey/Submission.vue";

const CANDIDATE_INFO = gql`
  query CandidateInfo($id: ID!) {
    user(id: $id) {
      id
      candidature {
        id
        submission {
          id
          ...submissionFragment
        }
      }
      student {
        id
        name
        gender
        cf
        birthday
        born_in
        born_in_province
        nationality
        address
        city
        province
        cap
        id_card_number
        id_card_type
        id_card_expiration_date
        other_nationality
        country_of_birth
        civic_number

        canVerifyAnagraphicProfile
        anagraphic_profile_verified_at
        anagraphicProfileVerifiedBy {
          id
          complete_name
        }

        canUpdate

        idCard: file(name: "idCard") {
          id
          name
          link
        }
        privacy: file(name: "privacy") {
          id
          name
          link
        }
        cv: file(name: "cv") {
          id
          name
          link
        }
      }
    }
  }
  ${SUBMISSION_FRAGMENT}
`;

export default {
  components: {
    StudentAnagraphicForm,
    EditCard,
    Submission,
  },

  props: {
    user_id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },
  apollo: {
    user: {
      query: CANDIDATE_INFO,
      variables() {
        return {
          id: this.user_id,
        };
      },
    },
  },
  computed: {
    student() {
      return this.user?.student;
    },
    candidature() {
      return this.user?.candidature;
    },
    loading() {
      return this.$apollo.queries.user.loading;
    },
    studentAnagraphicInfo() {
      const fields = [
        { field: "gender", type: "autocomplete" },
        { field: "birthday", type: "date" },
        { field: "country_of_birth" },
        { field: "born_in" },
        { field: "born_in_province" },
        { field: "nationality", type: "nationality" },
        { field: "other_nationality", type: "nationality" },
        { field: "province" },
        { field: "city" },
        { field: "cap" },
        { field: "address" },
        { field: "civic_number" },
        { field: "cf" },
        { field: "id_card_type", type: "autocomplete" },
        { field: "id_card_number" },
        { field: "id_card_expiration_date", type: "date" },
        { divider: true, cols: 12 },
        { field: "idCard", type: "file" },
        { field: "privacy", type: "file" },
        { field: "cv", type: "file" },
      ];

      return fields;
    },
  },
};
</script>
