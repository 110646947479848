<template>
  <v-card v-if="submission" rounded="lg" class="overflow-hidden">
    <v-card-title class="bg-primary text-uppercase"
      >{{ survey.title }}
      <v-spacer></v-spacer>
      <v-chip small color="primary" v-if="submission.submitted_at">
        {{ $t("submission.submitted_at") }} {{ moment(submission.submitted_at).format("L LTS") }}
      </v-chip>
    </v-card-title>
    <v-divider></v-divider>
    <v-stepper alt-labels flat :value="parseInt(survey.currentPageNo) + 1">
      <v-stepper-header>
        <v-stepper-step
          v-for="page in survey.visiblePages"
          :key="page.name"
          :step="parseInt(page.num)"
          :complete="survey.currentPageNo >= page.num"
        >
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-divider></v-divider>
    <v-card-text>
      <Survey :survey="survey"></Survey>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn :disabled="survey.isFirstPage" color="primary" @click="goToPreviousPage">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="!survey.isLastPage" color="primary" @click="goToNextPage">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn
        v-else-if="submission.canUpdate || submission.canSubmit"
        :disabled="!isLastPage"
        color="primary"
        @click="finish"
        >{{ $t("dialog.finish") }}</v-btn
      >
    </v-card-actions>
    <ConfirmDialog v-model="dialog" @confirm="storeSubmissionData(true)" :text="confirmText"></ConfirmDialog>
  </v-card>
  <v-skeleton-loader v-else type="article"></v-skeleton-loader>
</template>

<script>
import surveyMixin from "./mixins/survey";
import { Survey } from "survey-vue-ui";
import gql from "graphql-tag";
import ConfirmDialog from "../dialogs/ConfirmDialog.vue";
import { ucFirst } from "../../apollo/helpers";
import moment from "moment";

export const SUBMISSION_FRAGMENT = gql`
  fragment submissionFragment on SurveySubmission {
    id
    data
    survey {
      id
      data
    }
    submitted_at
    canUpdate
    canSubmit
  }
`;

export default {
  name: "SurveyPreview",
  mixins: [surveyMixin],
  components: { Survey, ConfirmDialog },
  props: {
    submission: {
      type: Object,
      required: true,
    },
  },
  data() {
    const survey = this.buildSurvey(this.submission.survey, JSON.parse(this.submission.data));

    if (!this.submission.canUpdate && !this.submission.canSubmit) {
      survey.mode = "display";
    }

    return {
      survey,
      dialog: false,
      confirmText: this.$t("submission.confirm_to_submit"),
    };
  },

  methods: {
    moment,
    storeSubmissionData(confirmed = false) {
      if (!confirmed) {
        this.dialog = true;
        return;
      }

      const action = this.submission.canUpdate ? "update" : "submit";

      this.$apollo.mutate({
        mutation: gql`
          mutation ${ucFirst(action)}SurveySubmission($id: ID!, $data: String!) {
            ${action}SurveySubmission(id: $id, data: $data) {
              id
              data
              submitted_at
              canSubmit
              canUpdate
            }
          }
        `,
        variables: {
          id: this.submission.id,
          data: JSON.stringify(this.survey.data),
        },
      });
    },
  },
};
</script>
