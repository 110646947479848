<template>
  <base-form
    model="abroadExperience"
    @save="abroad_experience_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.abroadExperience.loading"
    :currentValue="abroadExperience"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import { ABROAD_EXPERIENCE_FRAGMENT } from "../tables/AbroadExperiencesTable.vue";
import laterThan from "../../validators/laterThan";

const ABROAD_EXPERIENCE_FORM_FRAGMENT = gql`
  fragment abroadExperienceForm on AbroadExperience {
    id
    type
    country
    university
    start_date
    end_date
    abroad_certification: file(name: "abroad_certification") {
      id
      name
      link
    }
    student {
      id
    }
  }
`;

export default {
  components: { BaseForm },
  name: "AbroadExperienceForm",
  props: {
    abroad_experience_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    abroad_experience_id(val) {
      if (val == null) this.abroadExperience = {};
    },
  },

  apollo: {
    abroadExperience: {
      query: gql`
        query AbroadExperience($id: ID!) {
          abroadExperience(id: $id) {
            ...abroadExperienceForm
          }
        }
        ${ABROAD_EXPERIENCE_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.abroad_experience_id };
      },
      skip() {
        return this.abroad_experience_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      abroadExperience: {},
      saving: false,
      fields: [
        {
          name: "type",
          type: "autocomplete",
          items: [
            "Erasmus Plus (Study visit)",
            "Erasmus Plus (Traineeship)",
            "Erasmus Plus (Progetti di volontariato e cooperazione)",
            "Erasmus Mundus",
            "Mobility Exchange",
            "Training Course",
            "EucA mobility",
            "Traineeship",
            "Tesi all'estero",
            "Programma Ulisse",
            "Progetto di Ricerca",
          ].map((value) => ({
            text: value,
            value,
          })),
          validation: {
            required,
          },
          cols: 12,
        },
        {
          name: "country",
          type: "api",
          path: "countries",
          query: gql`
            query Countries {
              countries {
                id
                name
              }
            }
          `,
        },
        {
          name: "university",
          validation: {
            required,
          },
        },
        {
          name: "start_date",
          type: "date",
          validation: {
            required,
          },
        },
        {
          name: "end_date",
          type: "date",
          validation: {
            required,
            laterThan: laterThan("start_date"),
          },
        },
        {
          name: "abroad_certification",
          type: "file",
        },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "AbroadExperience",
            fragment: ABROAD_EXPERIENCE_FRAGMENT,
            fragment_name: "abroadExperience",
          }),
          variables: {
            id: this.abroad_experience_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("updated");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "AbroadExperience",
            fragment: ABROAD_EXPERIENCE_FRAGMENT,
            fragment_name: "abroadExperience",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createAbroadExperience } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createAbroadExperience);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
