<template>
  <v-container>
    <navigation-arrows
      v-if="$route.params.id && !loading"
      :next-user="user.nextUser"
      :previous-user="user.previousUser"
    />
    <v-card v-if="!loading" rounded="lg" class="overflow-hidden">
      <user-recap @deleted="$router.replace({ name: 'Users' })" flat :user_id="user_id"
    /></v-card>
    <v-skeleton-loader type="card" v-else />
    <br />
    <student v-if="!loading && user.type === 'student' && user.student" :student_id="parseInt(user.student.id)" />
    <Candidate v-if="!loading && user.type === 'candidate'" :user_id="user.id" />
  </v-container>
</template>

<script>
import UserRecap from "../components/users/UserRecap.vue";
import gql from "graphql-tag";
import Student from "../components/Student.vue";
import Candidate from "../components/profile/Candidate.vue";
import NavigationArrows from "../components/profile/NavigationArrows.vue";

export default {
  components: {
    Student,
    UserRecap,
    NavigationArrows,
    Candidate,
  },

  data() {
    return {
      page: 1,
      headers: [
        {
          text: "Data",
          value: "date",
        },
        {
          text: "Tipo",
          value: "type",
        },
      ],
    };
  },

  apollo: {
    user: {
      query() {
        if (this.$route.params.id)
          return gql`
            query User($user_id: ID!) {
              user(id: $user_id) {
                id
                type
                student {
                  id
                }
                candidature {
                  id
                }
                previousUser {
                  id
                  complete_name
                }
                nextUser {
                  id
                  complete_name
                }
              }
            }
          `;
        else {
          return gql`
            query Me {
              user: me {
                id
                type
                student {
                  id
                }
                candidature {
                  id
                }
              }
            }
          `;
        }
      },
      variables() {
        return this.$route.params.id ? { user_id: this.$route.params.id } : {};
      },
      error: (error) => console.log(error.graphQLErrors),
    },
  },

  methods: {},

  computed: {
    user_id() {
      return this.user?.id;
    },

    loading() {
      return this.$apollo.queries.user.loading;
    },

    error() {
      return this.$apollo.queries.user.error;
    },
  },
};
</script>
