import { Model } from "survey-core";

export default {
  provide() {
    return {
      editQuestion: () => {},
      removeQuestion: () => {},
    };
  },
  computed: {
    isFirstPage() {
      return this.survey?.isFirstPage;
    },
    isLastPage() {
      return this.survey?.isLastPage;
    },
  },
  methods: {
    buildSurvey({ data }, submissionData = null) {
      const survey = new Model(data);

      survey.css = {
        actionBar: {
          item: "cols-auto",
        },
        error: {
          root: "d-none",
        },
        question: {
          title: "d-none",
          mainRoot: "pa-2 ma-2 border-solid border",
        },
        matrix: {
          mainRoot: "pa-4 ma-2 border-solid border",
        },
        page: {
          title: "text-h6 font-weight-bold",
        },
        completedPage: "d-flex justify-center align-center",
        footer: "d-none",
        header: "d-none",
      };

      if (submissionData) survey.data = submissionData;

      return survey;
    },

    goToPreviousPage() {
      if (this.survey.validateCurrentPage()) this.survey.currentPageNo--;
    },

    goToNextPage() {
      if (this.survey.validateCurrentPage()) this.survey.currentPageNo++;
    },

    finish() {
      //this.survey.completeLastPage();
      if (this.storeSubmissionData) this.storeSubmissionData();
    },

    goToPage({ num }) {
      this.survey.currentPageNo = num - 1;
    },
  },
};
