<template>
  <edit-card
    hideEmptyFields
    :info="info"
    :item="studentYear"
    model="studentYear"
    :title="$tc('models.studentdocuments', 1)"
  >
    <template v-slot:form="{ close }">
      <student-year-documents-form :student_year_id="studentYear.id" @cancel="close" @save="close" />
    </template>
  </edit-card>
</template>

<script>
import StudentYearDocumentsForm, {
  STUDENT_YEAR_DOCUMENTS_FORM_FRAGMENT,
} from "../forms/StudentYearDocumentsForm.vue";
import EditCard from "../base/EditCard.vue";
import gql from "graphql-tag";

export const STUDENT_YEAR_DOCUMENTS_FRAGMENT = gql`
  fragment studentYearDocuments on StudentYear {
    ...studentYearDocumentsForm
    student {
      id
      cf
      user {
        id
        surname
      }
    }
  }
  ${STUDENT_YEAR_DOCUMENTS_FORM_FRAGMENT}
`;

export default {
  name: "StudentYearDocuments",

  components: { EditCard, StudentYearDocumentsForm },

  props: {
    studentYear: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      info: [
        { field: "contract", type: "file", filename: this.getPdfFilename("contract") },
        { field: "educationAgreement", type: "file", filename: this.getPdfFilename("educationAgreement") },
        { field: "regulation", type: "file", filename: this.getPdfFilename("regulation") },
        { field: "examsCertification", type: "file", filename: this.getPdfFilename("examsCertification") },
        { field: "iseeDocumentation", type: "file", filename: this.getPdfFilename("iseeDocumentation") },
        { field: "dsu", type: "file", filename: this.getPdfFilename("dsu") },
        { field: "pfp", type: "file", filename: this.getPdfFilename("pfp") },
        { field: "transcript", type: "file", filename: this.getPdfFilename("transcript") },
      ],
    };
  },

  methods: {
    getPdfFilename(filename) {
      if (filename == "transcript" || filename == "educationAgreement") return this.studentYear.student?.cf;
      else return `${this.$t(`studentYear.${filename}`)}_${this.studentYear.student?.user.surname}`;
    },
  },
};
</script>
