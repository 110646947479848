<template>
  <base-table
    no_duplicates
    :title="$tc('models.annotation', 2)"
    :items="annotations"
    :headers="headers"
    :tableProps="tableProps"
    @deleteItem="deleteAnnotation"
  >
    <template v-slot:[`creator`]="{ item }">
      <router-link :to="{ name: 'UserProfile', params: { id: item.creator.id } }">
        {{ item.creator.complete_name }}
      </router-link>
    </template>
    <template v-slot:expanded-item="{ item }">
      <h4 class="pb-2">{{ $t("annotation.text") }}</h4>
      <span class="notes">{{ item.text }}</span>
    </template>
    <template v-slot:form="{ selected_id, close, duplicating }">
      <annotation-form
        :annotation_id="selected_id"
        :baseItem="baseItem"
        @cancel="close"
        :duplicating="duplicating"
        @save="(annotation) => annotationUpdated(annotation, close)"
        @created="(annotation) => annotationCreated(annotation, close)"
      />
    </template>
  </base-table>
</template>

<script>
export const ANNOTATIONS_TABLE_FRAGMENT = gql`
  fragment annotationsTable on Annotation {
    id
    text
    created_at
    updated_at
    creator {
      id
      complete_name
    }
  }
`;

import BaseTable from "./BaseTable.vue";
import AnnotationForm from "../forms/AnnotationForm.vue";
import gql from "graphql-tag";
import moment from "moment";
import { deleteModelMutation } from "../../graphql/mutations";

export default {
  components: { BaseTable, AnnotationForm },
  name: "AnnotationsTable",
  props: {
    annotations: Array,
    baseItem: { type: Object, default: () => ({}) },
    tableProps: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      headers: [
        {
          value: "creator",
          text: this.$t("annotation.creator"),
          type: "template",
        },
        {
          value: "created_at",
          text: this.$t("headers.created_at"),
          type: "date",
        },
        {
          value: "updated_at",
          text: this.$t("headers.updated_at"),
          type: "date",
        },
      ],

      moment,
    };
  },

  methods: {
    annotationUpdated(annotation, close) {
      this.$emit("annotationUpdated", annotation);
      close();
    },

    annotationCreated(annotation, close) {
      this.$emit("annotationCreated", annotation);
      close();
    },

    deleteAnnotation(annotation) {
      this.$apollo
        .mutate({
          mutation: deleteModelMutation("Annotation"),
          variables: { id: annotation.id },
        })
        .then(() => {
          this.$emit("annotationDeleted", annotation);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.notes {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
</style>