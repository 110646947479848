<template>
  <vue-apex-charts :options="chartOptions" :series="series" v-if="series.length > 0" />
  <h4 v-else>{{ $t("no-data") }}</h4>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    series: { Array, required: true },
    labels: { Array, required: true },
    colors: { Array, required: true },
  },

  components: { VueApexCharts },

  computed: {
    chartOptions() {
      return {
        chart: {
          type: "donut",
        },
        labels: this.labels,
        legend: {
          position: "bottom",
          show: true,
        },
        dataLabels: {
          formatter(val) {
            return val.toFixed(1) + "%";
          },
          style: {
            fontSize: "1rem",
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
              dataLabels: {
                offset: -7,
              },
              labels: {
                show: true,
                total: {
                  showAlways: false,
                  show: true,
                  label: "Totale",
                  formatter({ globals: { series } }) {
                    return series?.reduce((a, b) => a + parseFloat(b), 0).toFixed(1);
                  },
                },
              },
            },
          },
        },
        colors: this.colors,
      };
    },
  },
};
</script>
