<template>
  <base-form
    model="studentYear"
    @save="uploadFiles"
    :fields="fields"
    :loading="$apollo.queries.studentYear.loading"
    :currentValue="studentYear"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
export const STUDENT_YEAR_DOCUMENTS_FORM_FRAGMENT = gql`
  fragment studentYearDocumentsForm on StudentYear {
    id

    contract: file(name: "contract") {
      ...file
    }

    educationAgreement: file(name: "educationAgreement") {
      ...file
    }

    iseeDocumentation: file(name: "iseeDocumentation") {
      ...file
    }

    examsCertification: file(name: "examsCertification") {
      ...file
    }

    transcript: file(name: "transcript") {
      ...file
    }

    pfp: file(name: "pfp") {
      ...file
    }

    regulation: file(name: "regulation") {
      ...file
    }

    dsu: file(name: "dsu") {
      ...file
    }
  }
  ${FILE_FRAGMENT}
`;

import gql from "graphql-tag";
import { FILE_FRAGMENT } from "../../graphql/fragments";
import BaseForm from "./BaseForm.vue";
import { updateMutationBuilder } from "../../graphql/mutations";

export default {
  components: { BaseForm },
  name: "StudentYearDocumentsForm",
  props: {
    student_year_id: { required: true },
    duplicating: Boolean,
  },

  apollo: {
    studentYear: {
      query: gql`
        query StudentYear($id: ID!) {
          studentYear(id: $id) {
            id
            student {
              id
              cf
            }
            ...studentYearDocumentsForm
          }
        }
        ${STUDENT_YEAR_DOCUMENTS_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.student_year_id };
      },
    },
  },
  data() {
    return {
      newValue: {},
      studentYear: {},
      saving: false,
    };
  },
  computed: {
    fields() {
      return [
        { name: "contract", type: "file" },
        { name: "educationAgreement", type: "file" },
        { name: "regulation", type: "file" },
        { name: "examsCertification", type: "file" },
        { name: "iseeDocumentation", type: "file" },
        { name: "dsu", type: "file" },
        { name: "pfp", type: "file" },
        { name: "transcript", type: "file", filename: this.studentYear.student?.cf },
      ];
    },
  },
  methods: {
    async uploadFiles() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "StudentYear",
            fragment: STUDENT_YEAR_DOCUMENTS_FORM_FRAGMENT,
            fragment_name: "studentYearDocumentsForm",
          }),
          variables: {
            id: this.student_year_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>

<style></style>
