<template>
  <v-dialog :value="value" @input="(value) => $emit('input', value)" persistent max-width="500px">
    <v-card>
      <v-card-title class="headline">{{ title ?? $t("dialog.confirm") }}</v-card-title>
      <v-card-text v-if="text">{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="$emit('input', false)">{{ $t("dialog.cancel") }}</v-btn>
        <v-btn color="primary" text @click="confirm">{{ $t("dialog.yes") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    text: String,
  },

  methods: {
    confirm() {
      this.$emit("confirm");
      this.$emit("input", false);
    },
  },
};
</script>
