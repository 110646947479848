<template>
  <base-events-chart :series="series" :labels="labels" :colors="colors"></base-events-chart>
</template>

<script>
import gql from "graphql-tag";
import BaseEventsChart from "./BaseEventsChart.vue";

export const PRESENCES_GROUPED_BY_TAG_FRAGMENT = gql`
  fragment presencesGroupedByTag on StudentYear {
    presencesGroupedByTag {
      tag {
        id
        name
        color
      }
      total
      presences_count
    }
  }
`;

export default {
  components: { BaseEventsChart },
  props: {
    presencesGroupedByTag: { Array, required: true },
  },
  computed: {
    series() {
      return this.presencesGroupedByTag.map((row) => row.total / 3600);
    },
    labels() {
      return this.presencesGroupedByTag.map((row) => (row.tag ? row.tag.name : this.$t("education.no_tag")));
    },
    colors() {
      return this.presencesGroupedByTag.map((row) => row.tag?.color || this.$vuetify.theme.themes.light.secondary);
    },
  },
};
</script>
