<template>
  <base-table
    :title="$tc('models.presence', 2)"
    :items="studentYear.presences"
    :headers="headers"
    :canDelete="false"
    :canEdit="false"
    :item-route="(item) => ({ name: 'Event', params: { id: item.id } })"
  >
    <template v-slot:[`name`]="{ item }">
      <router-link :to="{ name: 'Event', params: { id: item.id } }">{{ item.name }} </router-link>
    </template>

    <template v-slot:[`type`]="{ item }">
      {{ item.type ? $t(`options.event.type.${item.type}`) : "" }}
    </template>

    <template v-slot:[`start_timestamp`]="{ item }">
      {{ moment(item.start_timestamp).format("L") }}
    </template>

    <template v-slot:[`acknowledged`]="{ item }">
      <v-icon color="primary" v-if="item.acknowledged && item.pivot.acknowledged">mdi-check</v-icon>
      <v-tooltip bottom v-else-if="item.acknowledged && !item.pivot.acknowledged">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="warning" v-bind="attrs" v-on="on">mdi-exclamation</v-icon>
        </template>
        <span>{{ $t("presence.not_acknowledged") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="danger" v-bind="attrs" v-on="on">mdi-close</v-icon>
        </template>
        <span>{{ $t("presence.event_not_acknowledged") }}</span>
      </v-tooltip>
    </template>

    <template
      v-slot:[`course.tag.name`]="{
        item: {
          course: { tag },
        },
      }"
    >
      <v-chip :color="tag ? tag.color : ''" link label dark>
        {{ tag ? tag.name : "Nessuno" }}
      </v-chip>
    </template>
  </base-table>
</template>

<script>
import gql from "graphql-tag";
import BaseTable from "../../tables/BaseTable.vue";

export const PRESENCES_TABLE_FRAGMENT = gql`
  fragment presencesTable on StudentYear {
    id
    presences {
      id
      acknowledged
      start_timestamp
      end_timestamp
      duration
      name
      type
      pivot {
        acknowledged
        acknowledged_cfa
      }
      course {
        id
        tag {
          id
          name
          color
        }
      }
    }
  }
`;

export default {
  components: {
    BaseTable,
  },

  props: {
    studentYear: { Object, required: true },
  },

  data() {
    return {
      headers: [
        { value: "start_timestamp", text: this.$t("event.start_timestamp"), type: "date" },
        { value: "name", text: this.$t("event.name") },
        { value: "course.tag.name", text: this.$t("tag.name"), type: "template" },
        { value: "type", text: this.$t("event.type"), type: "template" },
        { value: "acknowledged", text: this.$t("event.acknowledged"), type: "template" },
        { value: "duration", text: this.$t("event.duration"), type: "duration" },
        {
          value: "pivot.acknowledged_cfa",
          text: this.$t("presence.acknowledged_cfa"),
          type: "decimalNumber",
          precision: 1,
        },
      ],
    };
  },

  methods: {},
};
</script>
