<template>
  <base-events-chart :series="series" :labels="labels" :colors="colors"></base-events-chart>
</template>

<script>
import gql from "graphql-tag";
import BaseEventsChart from "./BaseEventsChart.vue";
import { ucFirst } from "../../../apollo/helpers";

export const PRESENCES_GROUPED_BY_ACKNOWLEDGED_FRAGMENT = gql`
  fragment presencesGroupedByAcknowledged on StudentYear {
    presencesGroupedByAcknowledged {
      acknowledged
      total
      presences_count
    }
  }
`;

export default {
  props: {
    presencesGroupedByAcknowledged: { Array, required: true },
  },

  components: { BaseEventsChart },

  data() {
    return {
      colorMap: {
        acknowledged: this.$vuetify.theme.themes.light.primary,
        not_acknowledged: this.$vuetify.theme.themes.light.secondary,
      },
    };
  },

  computed: {
    series() {
      return this.presencesGroupedByAcknowledged.map((row) => row.total / 3600.0);
    },

    labels() {
      return this.presencesGroupedByAcknowledged.map((row) => ucFirst(this.$t(row.acknowledged ? `event.acknowledged` : "event.not_acknowledged")));
    },

    colors() {
      return this.presencesGroupedByAcknowledged.map((row) => (row.acknowledged ? this.colorMap.acknowledged : this.colorMap.not_acknowledged));
    },
  },
};
</script>
