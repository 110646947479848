<template>
  <div>
    <v-card v-if="!$apollo.queries.studentYear.loading" class="overflow-hidden" rounded="lg">
      <annotations-table
        :annotations="studentYear.annotations"
        :baseItem="{ studentYear }"
        :tableProps="{ dense: true, 'show-expand': true }"
        @annotationCreated="annotationCreated"
        @annotationDeleted="annotationDeleted"
      />
    </v-card>
    <v-skeleton-loader type="table" v-else />
  </div>
</template>

<script>
import gql from "graphql-tag";
import AnnotationsTable, { ANNOTATIONS_TABLE_FRAGMENT } from "../tables/AnnotationsTable.vue";
import { addToCacheArray, removeFromCacheArray } from "../../apollo/helpers";

const ANNOTATIONS_QUERY = gql`
  query StudentYearAnnotations($id: ID!) {
    studentYear(id: $id) {
      id
      annotations {
        ...annotationsTable
      }
    }
  }
  ${ANNOTATIONS_TABLE_FRAGMENT}
`;

export default {
  components: { AnnotationsTable },
  name: "StudentYearAnnotations",

  props: {
    student_year_id: Number,
  },

  data() {
    return { studentYear: { notes: [] } };
  },

  apollo: {
    studentYear: {
      query: ANNOTATIONS_QUERY,
      variables() {
        return {
          id: this.student_year_id,
        };
      },
    },
  },
  methods: {
    annotationCreated(newElement) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: ANNOTATIONS_QUERY,
          variables: {
            id: this.student_year_id,
          },
        },
        queryField: "studentYear.annotations",
        newElement,
        unshift: true,
      });
    },

    annotationDeleted({ id }) {
      removeFromCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: ANNOTATIONS_QUERY,
          variables: {
            id: this.student_year_id,
          },
        },
        queryField: "studentYear.annotations",
        id,
      });
    },
  },
};
</script>